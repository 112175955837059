import $ from 'jquery';

$(function() {

    let $body = $('body');

    let handleClick = function($this, $otherBtn) {
        if($this.data('active') === '1') {
            $this.data('active', '0');
            $this.removeClass('active');
            $($this.data('input')).val('').trigger('change');
        }
        else {
            $this.data('active', '1');
            $this.addClass('active');
            $($this.data('input')).val($this.data('value')).trigger('change');
            $otherBtn.removeClass('active');
            $otherBtn.data('active', '0');
        }
    };

    $body.on('click', '.yes-no-btn-yes', function() {
        let $this = $(this);
        let $noBtn = $($this.data('no-btn'));
        handleClick($this, $noBtn);
    });
    $body.on('click', '.yes-no-btn-no', function() {
        let $this = $(this);
        let $yesBtn = $($this.data('yes-btn'));
        handleClick($this, $yesBtn);
    });
});
