/* Any webpack imports relating to CSS/SASS goes here */
import css from '../styles/main.scss';
import Tooltip from 'tooltip.js';
import select2 from 'select2/dist/css/select2.css';
import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
library.add(far, fas);
dom.watch();
import $ from 'jquery';
import 'element-closest-polyfill';