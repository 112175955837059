import $ from "jquery";

$(function() {
    let $body = $('body');

    $body.on('click', '.checkbox', function (e) {
        if (e.target.tagName !== 'INPUT') {
            return;
        }

        let $this = $(this),
            checked = $this.hasClass('on');

        (state => {
            $this.removeClass(state ? 'on' : 'off').addClass(state ? 'off' : 'on');
            $this.find('svg').removeClass(state ? 'fa-toggle-on' : 'fa-toggle-off').addClass(state ? 'fa-toggle-off' : 'fa-toggle-on');
            $this.find('input[type=checkbox]').attr('checked', !state);
        })(checked);

        e.stopImmediatePropagation();
    });
});
