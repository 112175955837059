import $ from 'jquery';

$('textarea.js-rich-editor').each(function() {
    window.CKEDITOR.replace($(this).attr('id'), {
        toolbar: [
            {name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'RemoveFormat']},
            {name: 'lists', items: ['NumberedList', 'BulletedList']},
            {name: 'links', items: ['Link', 'Unlink']},
            {name: 'clipboard', items: ['Undo', 'Redo']},
            {name: 'editing', items: []},
            {name: 'document', items: ['Source']}
        ]
    });
});
