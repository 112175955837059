import $ from 'jquery';

$(function() {

    let $body = $('body');

    $body.on('change keyup paste', '.admin-list-inline form.adminlist-field-form :input', function (e) {

        if(e.type === 'keyup' && e.which !== 13) {
            return;
        }

        let $this = $(this),
            $inline = $this.closest('.admin-list-inline'),
            $form = $inline.find('form'),
            $loading = $inline.find('.loading'),
            data = $form.serializeArray(),
            path = $inline.data('update-path');



        $form.hide();
        $loading.show();

        data.push({name: '_redirect', value : false});

        let xhr = $.post(
            path,
            data,
            function (data, b, c) {
                $form.show();
                $loading.hide();
                $body.trigger('form.recalculate-original-values');
            }
        )
    });
});
