import $ from 'jquery';

$(function() {

    let $body = $('body');

    let btnClass = '.adminlist-field-modal-save-btn';

    $body.on('click', btnClass, function (e) {
        let $this = $(this),
            $modal = $this.closest('.modal'),
            $form = $modal.find('form'),
            data = $form.serializeArray(),
            path = $modal.data('update-path');

        $this.find('.label').hide();
        $this.find('.loading').show();

        data.push({name: '_redirect', value : false});

        let xhr = $.post(
            path,
            data,
            function (data, b, c) {
                $($modal.data('text')).html(data);
                $modal.modal('hide');
                $this.attr('disabled', 'disabled');
                $this.find('.label').show();
                $this.find('.loading').hide();
                $body.trigger('form.recalculate-original-values');
            }
        )
    });

    $body.on('change keyup paste', '.admin-list-modal form.adminlist-field-form :input', function (e) {
        let $this = $(this).closest('form'),
            originalValue = $this.data('original-value'),
            value = $this.serialize(),
            $button = $this.closest('.modal').find(btnClass);

        if (originalValue === value) {
            $button.attr('disabled', 'disabled');
        }
        else {
            $button.attr('disabled', false);
        }
    });
});
