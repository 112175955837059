import $ from 'jquery';

$(function() {
    $('body').on('reloadElement', function(e, data) {
        let $this = $(data.element);
        $.get($this.data('reload-path'), function(html) {
            $this.html(html);
        }).fail(function(error) {
            console.log(error)
        });
    });
});
