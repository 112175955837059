import $ from 'jquery';
import select2 from 'select2';

$(function() {

    $('body').on('click', '[data-toggle="modal"]', function(e) {
        e.preventDefault();
        var modal = document.querySelector($(this).data('target'));
        if (modal) {
            modal.classList.add('is-active')
        }
        let relatedTarget = e.currentTarget;
        let showEvent = $.Event('modal.show', { relatedTarget });
        $(modal).trigger(showEvent);
        $('body').attr('style', 'overflow:hidden;');
    });

    $('body').on('click', '.close-modal', function(e) {
        let modal = this.closest('.modal');
        let hideEvent = $.Event('modal.hide');
        $(modal).trigger(hideEvent);
        modal.classList.remove('is-active');
        $('body').attr('style', false);
    });

    let makeModal = function(title) {

        return '' +
            '   <div class="modal-background" onclick="this.closest(\'.modal\').classList.remove(\'is-active\')"></div>' +
            '   <div class="modal-card">' +
            '       <header class="modal-card-head">' +
            '           <p class="modal-card-title">' + title + '</p>' +
            '           <button class="delete" aria-label="close" onclick="this.closest(\'.modal\').classList.remove(\'is-active\')"></button>' +
            '       </header>' +
            '       <div class="modal-card-foot"></div>'+
            '   </div>';
    };

    $('#sharedModal').on('modal.show', function(e) {

        let $modal = $('#sharedModal');
        let $link = $(e.relatedTarget);

        $modal.html(makeModal('Loading ...'));

        let path = $link.attr('href');

        let getFormXhr = $.get(path, function(data) {
            // Do we have a full HTML page? It's probably the login page...
            if(data.substr(0, 15) === '<!DOCTYPE html>') {
                debugger;
                location.reload();
                return;
            }

            $modal.html(data);
            $modal.find('select.select2').select2();
            let $form = $modal.find('form');
            $('body').trigger("form.added", [$form]);
        });

        getFormXhr.fail(function(data) {
            console.log('fail ' + data.responseText.substr(0, 100));
            let modal = makeModal('An error occurred!');
            if(data.responseText !== 'An error has occurred') {
                modal += makeModal(data.responseText);
            }
            $('#sharedModal').html(modal);
        });

        let submitHandler = null;

        submitHandler = function(e) {
            let $form = $(this);
            if(!$form.valid()) {
                return;
            }

            let submitBtn = $form.find('.btn-primary');
            submitBtn.css('width', submitBtn.outerWidth() + 'px');
            submitBtn.find('.text').hide();
            submitBtn.find('.loading').show();

            e.preventDefault();
            let url = $form.attr('action');
            let data = $form.serialize();
            let xhr = $.post(url, data);

            $form.find('input, select, button, .select2, .btn').attr('disabled', true);

            xhr.done(function(data, b, c) {
                $modal.html(data);
                $modal.find('select.select2').select2();
                $modal.one('submit', 'form', submitHandler);
            });
            xhr.fail(function(a, b, c) {
                if(a.status === 302) {
                    document.location = a.responseText;
                }
                else {
                    $modal.html(makeModal('An error occurred!'));
                }
            });
        };

        $modal.one('submit', 'form', submitHandler);
    });

    $('#sharedModal').on('modal.hide', function(e) {
        let $modal = $('#sharedModal');
        $modal.off('submit');
    });
});
