import $ from 'jquery';
import Tooltip from 'tooltip.js';

$(() => {
    $('[data-toggle="tooltip"]').each(function() {
        let $this = $(this),
            classes = $this.data('tooltip-class');

        new Tooltip($this, {
            title: $this.attr('title'),
            placement: 'top',
            html: true,
            template: '<div class="tooltip ' + classes + '" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>'
        });

        $this.attr('title', '');
    });
});
