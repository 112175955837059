import $ from 'jquery';

$(function() {
    $('body').on('click', '.alert.alert-dismissible[data-dismiss-notification] .close', function () {
        let $alert = $(this).closest('.alert.alert-dismissible'),
            notice = $alert.data('dismiss-notification'),
            userId = $alert.data('dismiss-notification-user-id');
        let url = '/api/user-preferences/' + userId + '/dismissed-notices/' + notice;
        $.post(url);
    });
});
