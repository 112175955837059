import $ from 'jquery';

$(function() {
    let $body = $('body');
    let $searchInput = $('#js-user-search-input');
    let path = $searchInput.data('path');
    let searchTimout = null;
    $body.on('search change keyup paste', '#js-user-search-input', function (e) {

        if(searchTimout) {
            clearTimeout(searchTimout);
            searchTimout = null;
        }

        let searchTerm = { 'filter' : 'null' },
            doSearch = false;

        if($searchInput.val().length > 3) {
            searchTerm = { 'searchTerm' : $searchInput.val() };
            doSearch = true;
        }

        if($searchInput.val().length === 0) {
            searchTerm = {};
            doSearch = true;
        }

        if(doSearch) {
            let searchFunc = () => {
                $('.data-grid').attr('disabled', true);
                let xhr = $.get(
                    path,
                    searchTerm,
                    function (data, b, c) {
                        let content = $(data).find('.data-grid');
                        $('.data-grid').replaceWith(content);
                    }
                );
            };
            searchTimout = setTimeout(searchFunc, 500);
        }

    });
});
