import $ from 'jquery';
import 'imports-loader?define=>false,module.exports=>false!jquery-validation';
import './fields/checkbox.js';
import './fields/modal.js';
import './fields/inline.js';
import './fields/richText.js';
import './fields/yesNo.js';
import './fields/dropdown';

$(function() {

    let $body = $('body');

    let initValidation = function($target) {
        $target.validate({
            errorClass: 'help-block text-danger',
            errorPlacement: function(error, element) {
                let $parent = $(element).closest('.form-group');
                $parent.append(error);
            }
        });
    };

    initValidation($('form.validate'));

    $body.on("form.added", function(event, $form) {
        if($form.hasClass('validate')) {
            initValidation($form);
        }
    });
    //
    // $body.on('click', '.adminlist-switch.active', function(e) {
    //     let $this = $(this),
    //         $target = $(e.target).closest('svg'),
    //         value = $target.hasClass('on') ? '' : '1',
    //         path = $this.data('update-path'),
    //         csrf = $this.data('csrf'),
    //         data = {
    //             '_token' : csrf,
    //             'value' : value
    //         },
    //         svg = $this.find('svg');
    //
    //     svg.hide();
    //
    //     $this.find('svg.loading').show();
    //
    //     let xhr = $.post(
    //         path,
    //         data,
    //         function(data, b, c) {
    //             $this.find('svg.loading').hide();
    //             // alert(value);
    //             if(value === '1') {
    //                 $this.find('svg.on').show();
    //             }
    //             else {
    //                 $this.find('svg.off').show();
    //             }
    //         }
    //     )
    // });
    //
    // $('.adminlist-text.active').closest('td').on('click', function(e) {
    //     let $this = $(this),
    //         $input = $this.find('input');
    //     $input.show();
    //     $input.focus();
    //     $this.find('span.text').hide();
    // });
    //
    // let submitText = function(e) {
    //
    //     console.log(e.type, e.originalEvent.type);
    //
    //     let $this = $(this),
    //         $parent = $this.closest('.adminlist-text'),
    //         $text = $parent.find('.text'),
    //         value = $this.val(),
    //         originalValue = $this.data('original-value');
    //
    //     if($this.data('processing')) {
    //         console.log('already processing');
    //         return;
    //     }
    //     else {
    //         console.log('gonna process');
    //     }
    //     $this.data('processing', true);
    //
    //     if(value === originalValue) {
    //         $text.show();
    //         $this.hide();
    //         return;
    //     }
    //
    //     let path = $parent.data('update-path'),
    //         csrf = $this.data('csrf'),
    //         data = {
    //             '_token' : csrf,
    //             'value' : value
    //         },
    //         $loader = $parent.find('svg.loading');
    //
    //     $this.hide();
    //     $loader.show();
    //
    //     let xhr = $.post(
    //         path,
    //         data,
    //         function(data, b, c) {
    //             $this.data('processing', false);
    //             $loader.hide();
    //             $this.val(value);
    //             $this.data('original-value', value);
    //             $text.html(value);
    //             $text.show();
    //         }
    //     )
    // };
    //
    // $body.on('blur', '.adminlist-text.active input', submitText);
    // $body.on('keyup', '.adminlist-text.active input', function(e) {
    //     if(e.which === 13) {
    //         submitText.call($(this), e);
    //     }
    // });


    // Keep a track of forms that have changed.

    let getFormOriginalValues = function() {
        $('form').each(function() {
            let $this = $(this);
            $this.data('original-value', $this.serialize());
        });
    };

    getFormOriginalValues();

    var observer = new MutationObserver(function(mutations, observer) {
        getFormOriginalValues();
    });

    $body.on('form.recalculate-original-values', getFormOriginalValues);

    observer.observe(document, {
        childList: true,
        subtree: false,
        attributes: false
    });
});
