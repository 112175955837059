import $ from 'jquery';

$(function() {
    let $auditBox = $('.audit-box');
    $auditBox.find('.open-close-icon, .audit-answers-box').removeAttr('style');
    $('.js-toggle-audit-answers').on('click', function(e) {
        let $this = $(this);
        let $auditBox = $this.closest('.audit-box');
        if($auditBox.hasClass('not-open')) {
            $auditBox.removeClass('not-open').addClass('open');
        }
        else {
            $auditBox.removeClass('open').addClass('not-open');
        }
    });
});
