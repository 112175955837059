import $ from 'jquery';

$(function() {
    $('body').on('change', '#audit_form_auditTemplate', function(e) {
        let newValue = $('#audit_form_auditTemplate').val();
        if(newValue === '') {
            $('.audit-add .disableable-form').attr('disabled', false);
        }
        else {
            $('.audit-add .disableable-form').attr('disabled', 'disabled');
        }
    });
});
