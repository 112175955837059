import $ from 'jquery';

$(function() {
    $('body').on('click', '.card-toggle', e => {
        let $this = $(e.currentTarget);
        let $content = $this.closest('.expander').find('.card-content');
        let $caret = $this.find('svg');
        $content.toggleClass('is-hidden');
        $caret.toggleClass('fa-angle-down').toggleClass('fa-angle-up');
    });
});
