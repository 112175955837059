import $ from 'jquery';

$(function() {

    let $body = $('body');
    
    $body.on('click', '.dropdown:not(.is-hoverable) .dropdown-trigger', function() {
        let $this = $(this);
        $('.dropdown').removeClass('is-active');
        $this.parent().toggleClass('is-active');
    });
    
    $body.on('focus', '.dropdown:not(.is-hoverable) .dropdown-trigger', function() {
        let $this = $(this);
        $('.dropdown').removeClass('is-active');
        $this.parent().toggleClass('is-active');
    });
    
//     $body.on('focusout', '.dropdown:not(.is-hoverable) .dropdown-trigger', function() {
//         let $this = $(this);
//         console.log($this)
// //        $this.parent().toggleClass('is-active');
//     });
    
    $body.on('click', '.dropdown-item', function() {
        $('.dropdown').removeClass('is-active');
    });
    
    $body.on('click', function(ev) {
        if ($(ev.target).closest('.dropdown').length === 0) $('.dropdown').removeClass('is-active');
    });
});
