import $ from 'jquery';

$(function() {
    let $body = $('body');

    $body.on('change', '#launch_audit_template_for_user_auditTemplate', function(e) {
        let $this = $(this);
        $this.closest('form').find('.primary-action-button').attr('disabled', $this.val() === '');
    });

    $body.on('change', '#launch_audit_template_startNow', e => {
        let $this = $(e.currentTarget),
            $dateField = $('#launch_audit_template_startDate');

        if($this.is(":checked")) {
            $dateField.attr('disabled', true);
        }
        else {
            $dateField.attr('disabled', false);
        }
    });
});


