import $ from 'jquery';

$(() => {
    let $body = $('body'),
        time = 300,
        delay = function(_func) {
            setTimeout(_func, time)
        },
        disableAllSidePops = function () {
            $('.js-justification-button.is-active, .js-comment-button.is-active, .justification-container.is-active').removeClass('is-active');
            $('.js-side-pop.is-open').removeClass('is-open');
        },
        activateButton = function($this) {
            $this.addClass('is-active');
            $this.closest('.justification-container').addClass('is-active');
        },
        setAnswerButtonStates = function($this, data) {
            $this.find('[data-button].is-active').removeClass('is-active');
            $this.find('[data-button=\'' + data.answer + '\']').addClass('is-active');
            $('#comment-textarea-' + $this.data('id')).data('original-value', data.comment);
        };

    // Fire event for yes/no/na buttons
    $body.on('click', '[data-button]', e => {
        let $this = $(e.currentTarget),
            answer = $this.data('button');

        if($this.hasClass('is-active')) {
            answer = null;
        }

        let $answer = $this.closest('.js-answer');
        $answer.data('answer', answer);
        $answer.data('comment', $this.closest('.task-wrap').find('.js-comment-text').val());
        $this.closest('.js-answer').trigger('change');
    });

    $body.on('change', '.js-answer', e => {
        let $this = $(e.currentTarget),
            path = $this.data('path'),
            answer = $this.data('answer');

        delay(function() { $this.closest('.task-wrap').find('.js-comment-message').hide(); });

        if((answer === -1 || answer === 0)) {
            delay(function() { $this.closest('.task-wrap').find('.js-comment-message-' + (answer === 0 ? 'no' : 'na')).show(); });
            if($this.data('comment') === '') {
                $this.find('.js-comment-button').trigger('click');
                return;
            }
        }
        // Add yes/no/na loading state
        $this.addClass('is-loading');

        let $taskWrap = $this.closest('.task-wrap'), $answeredTeskBlock = $('#task-answered-info');

        $.post(
            path,
            $this.data(),
            function (data) {
                $this.removeClass('is-loading');
                $taskWrap.trigger('answer-updated', [$this.data(), data]);
                setAnswerButtonStates($this, $this.data());
                if(data.finishable !== undefined) {
                    $('#finish-audit-btn').data('finishable', data.finishable);
                    $body.trigger('update-finishable');
                }
                if($answeredTeskBlock !== undefined && $answeredTeskBlock.hasClass('is-hidden')){
                    $answeredTeskBlock.removeClass('is-hidden');
                   
                   let $topOffset  = $this.parents('section').first('.title').offset().top - $('.header').outerHeight();
                    $([document.documentElement, document.body]).animate({
                        scrollTop: $topOffset
                    }, 1000);
                }
            
            }
        );
    });


    $body.on('change', '#confirmation-checkbox', e => {
        $body.trigger('update-finishable');
    });

    $body.on('update-finishable', (e) => {
        let
            finishButton = $('#finish-audit-btn'),
            finishable = finishButton.data('finishable');

        if($('#confirmation-checkbox:checked').length > 0) {
            finishButton.attr('disabled', !finishable);
        }
        else {
            finishButton.attr('disabled', true);
        }
    });


    // Do updates to UI after answer AJAX call response is received.
    $body.on('answer-updated', '.task-wrap', (e, payload, result) => {
        let $this = $(e.currentTarget);
        $this.find('.js-comment-text').data('original-value', payload.comment);
        console.log(payload.comment);
        $this.find('.js-side-pop').removeClass('is-open');
        let $taskWrap = $this.closest('.task-wrap');

        delay(function () {
            $taskWrap.find('.js-information-button, .justification-container').removeClass('is-active');
            disableAllSidePops();
            $('.js-default-side-pop').addClass('is-open');
        });

        let $commentButton = $this.find('.js-comment-button'),
            $commentWrapper = $this.find('.comment-wrapper');

        if(payload.comment === '') {
            $commentButton.find('.edit').hide(100, () => {
                $commentButton.find('.add').show(100);
            });
            $commentWrapper.hide(100, function() {
                $commentWrapper.find('.comment-text').html('');
            });
        }
        else {
            $commentButton.find('.add').hide(100, () => {
                $commentButton.find('.edit').show(100);
            });
            $commentWrapper.find('.comment-text').html(payload.comment);
            $commentWrapper.show(100);
        }
    });

    // Open justification box
    $body.on('click', '.js-justification-button', e => {
        let $this = $(e.currentTarget);
        disableAllSidePops();
        delay(function () {
            activateButton($this);
            $this.closest('.task-wrap').find('.js-side-pop-justification').addClass('is-open');
        });
    });

    // Open comment box
    $body.on('click', '.js-comment-button', e => {
        let $this = $(e.currentTarget);
        disableAllSidePops();
        $('.js-comment-message').hide();
        delay(function () {
            activateButton($this);
            $this.closest('.task-wrap').find('.js-side-pop-comment').addClass('is-open');
            $('.js-side-pop-comment textarea').focus();
        });
    });

    // Close justification or comment box
    $body.on('click', '.js-close-popup', e => {
        let $this = $(e.currentTarget);
        let $taskWrap = $this.closest('.task-wrap');

        $.each($taskWrap.find('textarea'), function() {
            let $textarea = $(this);
            $textarea.val($textarea.data('original-value'));
        });

        $this.closest('.js-side-pop').removeClass('is-open');

        delay(function () {
            $taskWrap.find('.js-information-button, .justification-container').removeClass('is-active');
            disableAllSidePops();
            $('.js-default-side-pop').addClass('is-open');
        });
    });

    // Apply audit filter
    $body.on('click', '.dashboard-audit-filter', function(e) {
        e.preventDefault();
        let $this = $(e.currentTarget);
        let href = $this.data('path');
        $.get(href, function(content) {
            $('#task-list-container div').replaceWith(content);

            // Unhide this panel, has display: none set in markup to stop items flashing up before CSS takes effect.
            $('.js-side-pop-container').show();
        });
    });

    $body.on('click', '.js-submit-comment', function(e) {
        let $this = $(e.currentTarget),
            $textarea = $($this.data('textarea-target')),
            comment = $textarea.val();
        $body.trigger('comment-submitted', [$textarea.data('id'), comment]);
    });

    // Enable / disable submit on comment form.
    $body.on('change keyup paste', '.js-comment-text', e => {
        let $this = $(e.currentTarget),
            originalValue = $this.data('original-value'),
            value = $this.val(),
            $button = $this.closest('.js-comment-form').find('.js-submit-comment'),
            currentAnswer = $('.js-answer#answer-' + $this.data('id')).data('answer');

        if (originalValue === value || ((currentAnswer === 0 || currentAnswer === -1) && value === '')) {
            $button.attr('disabled', 'disabled');
        }
        else {
            $button.attr('disabled', false);
        }
    });

    $body.on('comment-submitted', (e, id, text) => {
        let $answer = $('#answer-' + id);
        $answer.data('comment', text);
        $answer.trigger('change');
    });

    $body.on('click', '.js-assign-user', e => {
        let $this = $(e.currentTarget),
            label = $this.html(),
            path = $this.data('path'),
            $label = $this.closest('.dropdown').find('.assigned-user-name'),
            $button = $this.closest('.dropdown').find('[aria-controls=\'dropdown-menu\']');

        $button.addClass('is-loading');

        $.post(path, [], data => {
            $label.html(label);
            $button.removeClass('is-loading');
            $this.closest('.dropdown-content').find('.dropdown-item').attr('disabled', false);
            $this.attr('disabled', true);
        });
    });

    // Unhide this panel, has display: none set in markup to stop items flashing up before CSS takes effect.
    $('.js-side-pop-container').show();

});

